import React from 'react'
import _kebabCase from 'lodash/kebabCase'
import { Link } from 'react-feather';
import { Divider } from 'ui-neumorphism'
import './SkillShowcase.css'
import 'ui-neumorphism/dist/index.css'

export default class SkillShowcase extends React.Component {
  static defaultProps = {
    items: [],
    className: '',
  }

  handleClick = event => event.target.classList.toggle('active')

  renderSkill(skill) {
    if (skill.url == null)
    {
      return skill.product
    }
    else
    {
      return <a href={skill.url} className="button">{skill.product}<Link color="orange" size={12} /></a>
    }
  }

  render() {
    const { items, className } = this.props
    return (
      <div className={`SkillShowcase ${className}`}>
        {!!items &&
         items.map(item => (
           <div
             className={`SkillShowcase--area `}
             key={`skill-area-${_kebabCase(item.heading)}`}
           >
             {!!item.heading &&
              <h2 className={'heading'}>
                <span>{item.heading}</span>
              </h2>
             }
             <Divider dense />
             {!!item.blurbs &&
              item.blurbs.map(blurb => (
                <div className={'blurb'}
                     key={`skill-area-${_kebabCase(item.heading)}-${_kebabCase(blurb.heading)}`}
                >
                  <h3 className="description">
                    {blurb.heading}
                  </h3>
                  {!!blurb.text &&
                   <span>
                     {blurb.text} <br />
                   </span>
                  }
                  <ul className={'skilllist'}>
                    {!!blurb.skills &&
                     blurb.skills.map(skill => (
                       <li className={'skill'}
                           key={`skill-area-${_kebabCase(item.heading)}-${_kebabCase(blurb.heading)}-${_kebabCase(skill.product)}`}
                       >
                         {this.renderSkill(skill)}
                       </li>
                     ))}
                  </ul>
                </div>
              ))}
           </div>
         ))}
      </div>
    )
  }
}
